import { CordovaGlobal } from 'apps/acp/packages/environment';

export const isPreOnboardingRedirectionNeeded = async (
  preOnboardingEnabled: boolean | 'mobile' | 'CARE',
  permissions: <R extends string>(
    permissions: { [perm in R]: boolean }
  ) => Promise<boolean>,
  cordova: CordovaGlobal | undefined
): Promise<boolean> => {
  const getPreOnboardingPermission = async (): Promise<boolean> =>
    await permissions({
      'webapi:show_pre_onboarding': true
    });

  const isPreOnboardingAvailable: boolean =
    preOnboardingEnabled === 'mobile'
      ? true
      : preOnboardingEnabled === 'CARE'
      ? await getPreOnboardingPermission()
      : preOnboardingEnabled;

  const hasPreviouslyLoggedIn = localStorage.getItem(
    'acp_has_previously_logged_in'
  );
  const isAlreadySeen = sessionStorage.getItem('acp_pre_onboarding_loaded');

  return (
    !!cordova &&
    !hasPreviouslyLoggedIn &&
    isPreOnboardingAvailable &&
    !isAlreadySeen
  );
};
